import {combineReducers} from 'redux';
import Reducers from '../reducers';
import storage from 'redux-persist/lib/storage';
import {configureStore} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import {patientSuspicionsApi} from '../services/patientSuspicions/patientSuspicions.service';
import {suspicionsApi} from '../services/suspicions/suspicions.service';
import {patientApi} from '../services/patient/patient.service';
import {assessmentsApi} from '../services/assessments/assessments.service'
import {hospitalUnityApi} from '../services/hospitalUnity/hospitalUnity.service'
import {infectionApi} from '../services/infection/infection.service'
import {setupListeners} from '@reduxjs/toolkit/dist/query/react';
import {settingsApi} from '../services/settings/settings.service';
import {patientCommentsApi} from '../services/patientComments/patientComments.service';
import {healthStatusApi} from '../services/health/health.service';
import {dashboardApi} from "../services/dashboard/dashboard.service";
import {patientPrescriptionsApi} from "../services/prescriptions/patientPrescriptions.service";
import { companiesApi } from '../services/companies/companies.service';


const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['infectionTypes']
};

// const persistedReducer = persistReducer(persistConfig, Reducers);


export const store = configureStore({
  reducer: Reducers,
  devTools: process.env.NODE_ENV !== 'production',
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      isImmutable: false,
      serializableCheck: false
    })
      .concat(hospitalUnityApi.middleware)
      .concat(suspicionsApi.middleware)
      .concat(patientApi.middleware)
      .concat(patientCommentsApi.middleware)
      .concat(assessmentsApi.middleware)
      .concat(infectionApi.middleware)
      .concat(settingsApi.middleware)
      .concat(healthStatusApi.middleware)
      .concat(patientSuspicionsApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(patientPrescriptionsApi.middleware)
      .concat(companiesApi.middleware)
})
setupListeners(store.dispatch);
export const persistor = persistStore(store);



