import moment from "moment";

export const patientPillsRequestBody = ({
    patientId,
    // TODO Qualis precisa dicidir essa data inicial de busca dos dados
    initialDate = moment('2020-01-01'),
    finalDate = moment(),
  }) => ({
    query: {
      bool: {
        should: [
          {
            bool: {
              must: {
                term: {
                  paciente_id: patientId,
                },
              },
              minimum_should_match: 1,
              should: [
                {
                  range: {
                    dthr_pedido: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
                {
                  range: {
                    dthr_entrega_laudo: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
              ],
              filter: {
                term: {
                  _index: 'laudos',
                },
              },
            },
          },
          {
            bool: {
              minimum_should_match: 2,
              should: [
                {
                  range: {
                    dt_encontro: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
                {
                  match: {
                    prontuario: patientId,
                  },
                },
              ],
              filter: {
                term: {
                  _index: 'encontros',
                },
              },
            },
          },
          {
            bool: {
              must: {
                term: {
                  paciente_id: patientId,
                },
              },
              minimum_should_match: 1,
              should: [
                {
                  range: {
                    dthr_pedido: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
                {
                  range: {
                    dthr_entrega: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
              ],
              filter: {
                term: {
                  _index: 'exames',
                },
              },
            },
          },
          {
            bool: {
              minimum_should_match: 2,
              should: [
                {
                  range: {
                    dthr_coleta: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
                {
                  match: {
                    paciente_id: patientId,
                  },
                },
              ],
              filter: {
                term: {
                  _index: 'sinais-vitais',
                },
              },
            },
          },
          {
            bool: {
              minimum_should_match: 2,
              should: [
                {
                  range: {
                    dthr_prescricao: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
                {
                  match: {
                    paciente_id: patientId,
                  },
                },
              ],
              filter: {
                term: {
                  _index: 'medicamentos',
                },
              },
            },
          },
          {
            bool: {
              must: {
                term: {
                  paciente_id: patientId,
                },
              },
              minimum_should_match: 1,
              should: [
                {
                  range: {
                    dthr_criacao: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
                {
                  range: {
                    dthr_fim_procedimento: {
                      gte: initialDate.toISOString(),
                      lte: finalDate.toISOString(),
                    },
                  },
                },
              ],
              filter: {
                term: {
                  _index: 'procedimentos',
                },
              },
            },
          },
        ],
      },
    },
    aggs: {
      by_index: {
        terms: {
          field: '_index',
        },
      },
    },
  });