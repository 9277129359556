import React from 'react';
import moment from 'moment';

import ChipsError from '../../../components/molecules/HorizontalChipsError';
import ChipsSuccess from '../../../components/molecules/HorizontalChipsSuccess';
import { ChipsBasic } from '../../../components/molecules/ChipsBasic/ChipsBasic';
import { DataTableSuspicions } from '../../../components/organisms/DataTable/DataTableSuspicions';
import { DataTableAssessment } from '../../../components/organisms/DataTable/DataTableAssessment';
import { Box, withStyles } from '@material-ui/core';
import { HistoryActions } from './HistoryActions';
const LocationText = withStyles(() => ({ root: { lineHeight: '1.2' } }))(Box);
export const historyColumnsDef = [
  {
    field: 'pacienteName',
    headerName: 'Paciente',
    valueGetter: ({ row }) =>
      row.paciente ? `${row.paciente?.name}` : '--',
    sortComparator: (v1, v2, cell01, cell02) => {
      const paciente01 = cell01.api.getRow(cell01.id).paciente;
      const paciente02 = cell02.api.getRow(cell02.id).paciente;
      return paciente01?.name.localeCompare(paciente02?.name);
    },
    minWidth: 200,
  },
  {
    field: 'dtInfeccao',
    headerName: 'Data infecção',
    minWidth: 140,
    valueGetter: ({ row }) => moment(row.dtInfeccao).format('DD/MM/YYYY'),
    flex: 1,
  },
  {
    field: 'avaliacao',
    headerName: 'Avaliação',
    minWidth: 140,
    flex: 1,
    renderCell: ({ row: assessment }) => (
      <DataTableAssessment assessment={assessment} />
    ),
  },
  {
    field: 'suspeitas',
    headerName: 'Suspeitas',
    minWidth: 280,
    flex: 1,
    renderCell: ({ row }) => (
      <DataTableSuspicions patient={row.suspeitaPayload} />
    ),
  },
  {
    field: 'localInfeccao',
    headerName: 'Local',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => (
      <LocationText>{row.localInfeccao || '--'}</LocationText>
    ),
  },
  {
    field: 'avaliador',
    headerName: 'Avaliador',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => (
      <LocationText>
        {row.avaliacaoResponsavel} <br />{' '}
        {moment(row.avaliacaoData).format('DD/MM/YYYY, HH:mm')}
      </LocationText>
    ),
  },
  {
    field: ' ',
    headerName: ' ',
    minWidth: 100,
    renderCell: ({ row }) => <HistoryActions assessment={row} />,
  },
];
